import React, { useState } from 'react';
import Head from '../components/Head';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';

import carteVins from '../img/carteVins.jpeg';
import carteBoissons from '../img/carteBoissons.jpeg';
import carte from '../img/carte.jpeg';
import formule from '../img/formuleMidi.jpeg';

import '../styles/menu.css';

function Menu() {
    const [selectedImage, setSelectedImage] = useState(null);

    const handleImageClick = (image) => {
        setSelectedImage(image);
    };

    const closeOverlay = () => {
        setSelectedImage(null);
    };

    return (
        <div>
            <Head />
            <Navbar />

            <div className='cartes'>
                <h2 className='titleCarte'>Découvrez notre carte</h2>
                <div className='row'>
                    <img src={carte} alt="" onClick={() => handleImageClick(carte)} />
                    <img src={formule} alt="" onClick={() => handleImageClick(formule)} />
                </div>
                <div className='row'>
                    <img src={carteBoissons} alt="" onClick={() => handleImageClick(carteBoissons)} />
                    <img src={carteVins} alt="" onClick={() => handleImageClick(carteVins)} />
                </div>
            </div>

            <Footer/>

            {selectedImage && (
                <div className="overlay" onClick={closeOverlay}>
                    <img src={selectedImage} alt="Selected" className="full-screen-image" />
                </div>
            )}
        </div>
    );
}

export default Menu;
import Head from '../components/Head';
import Navbar from '../components/Navbar';
import ImagesCrepes from '../components/ImagesCrepes';
import Footer from '../components/Footer';

function Crepes(){
    return (
        <div>   
            <Head />
            <Navbar />
            <ImagesCrepes />
            <Footer />
        </div>
    )
}

export default Crepes;
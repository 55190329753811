import '../styles/infospratiques.css';
import horaire from '../img/horaires.png'

function Horaires(){
    return(
        <div className='horaires'>
            <img src={horaire} alt=''/>
        </div>
    )
}

export default Horaires;
import '../styles/infospratiques.css';

function Card({ imgSrc, title, address, id }) {
    return (
        <div className='card'>
            <img src={imgSrc} alt={title} id={id}/>
            <h2>{title}</h2>
            <p>{address}</p>
        </div>
    );
}

export default Card;
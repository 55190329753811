import '../styles/head.css'

function HeadLeft(){
    return(
        <div className='discover'>
            <h3>Découvrez aussi</h3>
            <h5>Les douceurs du logis</h5>
        </div>
    )
}

export default HeadLeft;
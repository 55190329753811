import CreperieLigne from "./CreperieLigne";

function Creperie(){
    return (
        <div id="histoire">
            <CreperieLigne/>
        </div>
    )
}

export default Creperie;
import '../styles/CardCrepe.css'

import CardCrepe from './CardCrepe';

function ImagesCrepes(){
    return (
        <div>
            <CardCrepe />
        </div>
    )
}

export default ImagesCrepes;
import Head from '../components/Head';
import Navbar from '../components/Navbar';
import Accueil from '../components/Accueil';
import Creperie from '../components/Creperie';
import InfosPratiques from '../components/InfosPratiques';
import Footer from '../components/Footer';

function Index(){
    return (
        <div>
            <Head/>
            <Navbar/>
            <Accueil/>
            <Creperie/>
            <InfosPratiques/>
            <Footer />
        </div>
    )
}

export default Index;
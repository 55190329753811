import HeadLeft from "./HeadLeft";
import HeadRight from "./HeadRight";

function Head(){
    return(
        <div className="head">
            <HeadLeft/>
            <HeadRight/>
        </div>
    )
}

export default Head;
import React from 'react';
import '../styles/navbar.css';

import { Link } from 'react-router-dom';

function Navbar() {
    return (
        <nav className="navbar">
            <ul>
                <li>
                    <Link to={"/"}>
                        La crêperie
                    </Link>
                </li>
                <li>
                    <Link to={"/crepes"}>
                        Nos crêpes
                    </Link>
                </li>
            </ul>
        </nav>
    );
}

export default Navbar;
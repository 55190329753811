import '../styles/creperie.css';
import creperie1 from '../img/creperie1.jpeg';
import creperie2 from '../img/creperie2.jpeg';

function CreperieLigne(){
    return (
        <div>
            <div className="ligne">
                <p>Ouvrir cette crêperie sur le site même du cœur historique du château de La Garnache était une opportunité unique car je suis tombée sous le charme du lieu ! Et je ne doute pas qu'à votre tour, le lieu éveillera vos différents sens. Imaginez-vous déjeuner, dîner ou partager un verre en amoureux, en famille ou entre amis, avec une vue unique sur une motte féodale du 5ème siècle et des vestiges du vieux château de La Garnache (la tour carrée, la tour du Roi, la tour des filles, les murailles...) qualifié de "l'une des belles et fortes places du Bas Poitou" en 1468, par le Roi de France Louis XI et immergé en pleine nature dans un parc de 17 000 m², au milieu des jardins clos et au bord du ruisseau de la Seudre.</p>
                <img src={creperie1} alt=""/>
            </div>
            <div className="ligne2">
                <img src={creperie2} alt=""/>
                <p id='text'>Originaire du Nord de la France, j’ai découvert la Vendée il y a quelques mois par le biais du basket-ball, une autre passion chez moi, avec la cuisine.
                Très rapidement, j’ai été extrêmement bien accueillie par les Vendéens. J’ai rencontré des hommes et des femmes attachés à leurs territoires, solidaires et ayant le goût d’entreprendre.
                <br/>Autant de valeurs qui étaient déjà ancrées en moi et qui m’ont donné l’envie de me lancer dans ce projet de crêperie avec chambres d'hôtes.
                Et je souhaite faire de cet établissement un lieu où chacun pourra déguster des crêpes et des galettes, mais aussi un lieu de vie propice aux animations, aux événements et aux rencontres.
                <br/><br/>Au plaisir de vous accueillir
                <br/>Sabine</p>
            </div>
        </div>
    )
}

export default CreperieLigne;
import '../styles/infospratiques.css';
import Card from './Card';
import Horaires from './Horaires';
import localisateur from '../img/localisateur.png'
import telephone from '../img/silhouette-de-poignee-de-telephone.png'

function InfosPratiques(){
    return (
        <div>
            <h1 className='title'>Informations pratiques</h1>
            
            <div className='cards'>
                <Card
                    id={'loc'}
                    imgSrc={localisateur}
                    title="Où nous trouver ?"
                    address="1 Rue de Challans, 85710 La Garnache"
                />
                <Card
                    id={'tel'}
                    imgSrc={telephone}
                    title="Ou par téléphone ?"
                    address="+33 2 51 60 19 89"
                />
            </div>
            <Horaires/>
        </div>
    )
}

export default InfosPratiques;
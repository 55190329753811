import "../styles/infospratiques.css"

function Footer(){
    return(
        <footer>
            <p>Site développé par Corentin DELDALLE</p>
        </footer>
    )
}

export default Footer;
import '../styles/accueil.css'

function Accueil(){
    return(
        <div className='accueil'>
            <div className="text">
                <h1>LA CREPERIE DU CHATEAU</h1>
                <h4>A La Garnache</h4>

                <div className="buttons">
                    <p>+33 2 51 60 19 89</p>
                    <p>1 Rue de Challans, 85710 La Garnache</p>
                    <p>Contactez-nous</p>
                </div>
            </div>
            <div className='image'>

            </div>
        </div>
    )
}

export default Accueil;
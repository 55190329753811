import '../styles/head.css';
import instagram from '../img/instagram.png';
import facebook from '../img/facebook.png';
import localisateur from '../img/localisateur.png';

function HeadRight() {
    return (
        <div className='icones'>
            <a href="https://www.instagram.com" target="_blank" rel="noopener noreferrer">
                <img src={instagram} alt="Instagram" />
            </a>
            <a href="https://www.facebook.com" target="_blank" rel="noopener noreferrer" id='facebook'>
                <img src={facebook} alt="Facebook" />
            </a>
            <a href="https://maps.app.goo.gl/3ghYifMCrpjbYxLZA" target="_blank" rel="noopener noreferrer">
                <img src={localisateur} alt="Localisateur" />
            </a>
        </div>
    );
}

export default HeadRight;
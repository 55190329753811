import './App.css';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

import Index from './pages';
import Crepes from './pages/crepes';
import Menu from './pages/menu';

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route element={<Index/>} path="/"/>
          <Route element={<Crepes/>} path="/crepes"/>
          <Route element={<Menu/>} path="/menu"/>
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
